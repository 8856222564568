@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.article__body {
  ::v-deep img {
    max-width: 100%;
    height: auto;
  }
}
